import App from './Map'; // Replace 'YourAppFileName' with the actual filename containing your App component code

function MainApp() {
  return (
    <div className="MainApp">
      <App />
    </div>
  );
}

export default MainApp;
